import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { authAxios } from '../../services/auth-axios';

export function FormatModal({ format, onFormatSave, operation, isOpen, onFinish, children }) {
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [form] = Form.useForm();

  const settingsByOperation = {
    edit: {
      operation: authAxios().put,
      url: `/formats/${format?.id}`,
    },
    create: {
      operation: authAxios().post,
      url: '/formats',
    },
  };
  const setting = settingsByOperation[operation];

  const fetchGames = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get('/games', { params: { size: 1000 } });
      return response.data.content;
    } catch (error) {
      console.error('Error fetching formats:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      fetchGames()
        .then((gamesResponse) => setGames(gamesResponse))
        .catch((error) => console.error('Error fetching games:', error))
        .finally(() => setLoading(false));
    }
  }, [isOpen]);

  const buildGameOptions = () =>
    games.map((game) => ({
      value: game.id,
      label: (
        <span>
          {game.name} - {game.shortName}
        </span>
      ),
    }));

  const handleFinish = async (values) => {
    setLoading(true);

    try {
      await setting.operation(setting.url, { ...values });
      notification.success({ message: 'Formato salvo com sucesso!' });
    } catch (error) {
      console.error('Error adding format:', error);
      notification.error({ message: 'Erro ao salvar formato!', description: error.response.data.message });
    } finally {
      setLoading(false);
      onFinish();
      onFormatSave();
    }
  };

  return (
    <>
      {children}
      <Modal open={isOpen} onCancel={onFinish} footer={null} afterClose={form.resetFields}>
        <Card title="Salvar Formato">
          <Form initialValues={format} layout="vertical" onFinish={handleFinish} form={form} id="addFormatForm">
            <Form.Item name="name" label="Nome" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Adicione o nome do formato" />
            </Form.Item>

            <Form.Item label="Jogo relacionado" name={['game', 'id']} required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Select options={buildGameOptions()} placeholder="Selecione o jogo relacionado" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button disabled={loading} form="addFormatForm" type="primary" htmlType="submit" block>
                    Salvar Formato
                  </Button>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={onFinish}>
                    Fechar
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
}
