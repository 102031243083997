import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Flex, notification, Popconfirm, Row, Spin, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/AuthProvider';
import { buildPagination, DEFAULT_PAGINATION_PARAMS } from '../shared/build-pagination';
import { authAxios } from '../../services/auth-axios';
import { FormatModal } from './FormatModal';

export function Formats() {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [formats, setFormats] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({ ...DEFAULT_PAGINATION_PARAMS });
  const roles = localStorage.getItem('roles');
  const isAdmin = roles && roles.includes('ADMIN');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currentFormatBeingEdited, setCurrentFormatBeingEdited] = useState(false);

  const openAdd = () => setAddModalOpen(true);
  const closeAdd = () => setAddModalOpen(false);
  const closeEdit = () => setCurrentFormatBeingEdited(false);

  const fetchFormats = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get('/formats', {
        params: paginationOptions,
      });
      setFormats(response.data);
    } catch (error) {
      console.error('Error fetching games:', error);
      notification.error({ message: 'Erro ao buscar formatos' });
      if (error.response && error.response.status === 401) {
        auth.logOut();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await authAxios().delete(`/formats/${id}`);
      await fetchFormats();
      notification.success({ message: 'Formato deleteado com sucesso!' });
    } catch (error) {
      console.error('Error fetching games:', error);
      notification.error({ message: 'Erro ao deletar formato' });
      if (error.response && error.response.status === 401) {
        auth.logOut();
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePaginate = (pageNumber, size) =>
    setPaginationOptions({
      ...paginationOptions,
      size,
      page: pageNumber - 1,
    });

  useEffect(() => {
    fetchFormats();
  }, [auth, paginationOptions]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: 'Jogo relacionado',
      dataIndex: ['game', 'id'],
      key: 'game_id',
      width: 220,
      render: (_, { game: { name, shortName } }) => (
        <span>
          {name} - {shortName}
        </span>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      width: 220,
      render: (_, format) => (
        <Flex gap="small" wrap="wrap">
          <Popconfirm title="Deletar formato" description="Tem certeza que deseja deletar esse formato?" onConfirm={() => handleDelete(format.id)} okText="Sim, tenho certeza" cancelText="Cancelar">
            <Button type="primary" danger icon={<DeleteOutlined />} size="large" />
          </Popconfirm>

          <FormatModal format={format} onFormatSave={fetchFormats} isOpen={format.id === currentFormatBeingEdited} onFinish={closeEdit} operation="edit">
            <Button type="primary" onClick={() => setCurrentFormatBeingEdited(format.id)} icon={<EditOutlined />} size="large" />
          </FormatModal>
        </Flex>
      ),
    },
  ];

  if (loading) return <Spin size="large" />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {isAdmin && (
            <Col xs={24} md={8}>
              <Card title="Ações" size="small">
                <FormatModal onFormatSave={fetchFormats} isOpen={addModalOpen} onFinish={closeAdd} operation="create">
                  <Button type="primary" onClick={openAdd}>
                    Novo Formato
                  </Button>
                </FormatModal>
              </Card>
            </Col>
          )}
        </Row>
      </Col>

      <Col span={24}>
        <Table columns={columns} dataSource={formats.content} pagination={buildPagination(formats, handlePaginate)} />
      </Col>
    </Row>
  );
}
