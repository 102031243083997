import React, { useEffect, useState } from 'react';
import { Card, Col, notification, Row, Spin, Table, Tag } from 'antd';
import { useAuth } from '../../hooks/AuthProvider';
import AddMovementModal from './AddMovementModal';
import { Balance } from './Balance';
import { buildPagination, DEFAULT_PAGINATION_PARAMS } from '../shared/build-pagination';
import { formatDateWithHours, getAmountStyle, getTypeColor, getTypeLabel } from '../shared/helpers';
import { fetchBalance, fetchMovements } from './requests';

export function UserMovementsAdmin({ userId }) {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [movements, setMovements] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({ ...DEFAULT_PAGINATION_PARAMS });
  const [highestBalance, setHighestBalance] = useState();

  const performFetchMovements = () => fetchMovements({ userId, paginationOptions, onFinish: setMovements, setLoading, auth });
  const performFetchBalance = () => fetchBalance({ userId, onFinish: setHighestBalance, auth });

  const updateMovements = async () => {
    await performFetchMovements();
    await performFetchBalance();

    notification.success({ message: 'Movimento cadastrado com sucesso!' });
  };

  const handlePaginate = (pageNumber, size) =>
    setPaginationOptions({
      ...paginationOptions,
      size,
      page: pageNumber - 1,
    });

  useEffect(() => {
    performFetchMovements();
    performFetchBalance();
  }, [auth, userId, paginationOptions]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      width: 120,
      render: (type) => (
        <Tag color={getTypeColor(type)} key={type}>
          {getTypeLabel(type)}
        </Tag>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      width: 120,
      render: (amount) => <span style={getAmountStyle(amount)}>R${amount}</span>,
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      width: 220,
      render: (date) => formatDateWithHours(date),
    },
    {
      title: 'Saldo',
      dataIndex: 'balanceAfterMovement',
      key: 'balanceAfterMovement',
      width: 120,
      render: (balanceAfterMovement) => <span style={getAmountStyle(balanceAfterMovement)}>R${balanceAfterMovement}</span>,
    },
    {
      title: 'Observação',
      dataIndex: 'observation',
      key: 'observation',
      ellipsis: true,
    },
  ];

  if (loading) return <Spin size="large" />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Card title="Saldo Atual" size="small">
              <Balance balance={highestBalance} />
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card title="Ações" size="small">
              <AddMovementModal userId={userId} onMovementAdded={updateMovements} />
            </Card>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Table columns={columns} dataSource={movements.content} pagination={buildPagination(movements, handlePaginate)} />
      </Col>
    </Row>
  );
}
