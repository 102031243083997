import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import { authAxios } from '../../services/auth-axios';

export function PasswordRecoveryForm() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handlePasswordRecovery = async ({ email }) => {
    const handleError = () => {
      notification.error({
        message: 'Erro ao Enviar Solicitação',
        description: 'Erro ao enviar a solicitação de recuperação de senha. Verifique seu endereço de e-mail e tente novamente.',
        duration: 10,
      });
    };

    try {
      await authAxios().post('/users/token', { email });

      notification.success({
        message: 'Solicitação Enviada',
        description: 'Um e-mail com instruções para a recuperação de senha foi enviado para o seu endereço de e-mail.',
        duration: 10,
      });

      setTimeout(() => {
        navigate('/password-recovery-confirm');
      }, 2000);
    } catch (error) {
      handleError();
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center">
      <Col md={16} sm={24}>
        <Card title="Esqueci minha senha">
          <Card.Meta
            description="Ao clicar no botão abaixo e preencher seu email, você receberá automaticamente um email contendo
                as instruções necessárias para a mudança de senha."
          />
          <Form style={{ marginTop: 16 }} layout="vertical" onFinish={handlePasswordRecovery} form={form} id="passwordRecoveryForm">
            <Form.Item
              name="email"
              label="Email"
              required
              rules={[
                { type: 'email', message: 'Isso não parece um e-mail válido 🤔' },
                { required: true, message: 'Campo obrigatório!' },
              ]}
            >
              <Input placeholder="Digite seu e-mail" />
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button disabled={loading} form="passwordRecoveryForm" htmlType="submit" block type="primary">
                  Enviar Solicitação de Recuperação de Senha
                </Button>
              </Col>
              <Col span={24}>
                <Button disabled={loading} onClick={() => navigate('/login')} block>
                  Voltar ao login
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
