import { format } from 'date-fns';

export const formatDateWithHours = (date) => format(new Date(date), 'dd/MM/yyyy HH:mm:ss');

export const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy');

export const getTypeLabel = (type) =>
  ({
    CREDIT: 'Crédito',
    DEBIT: 'Débito',
  })[type] || 'Desconhecido';

export const getTypeColor = (type) =>
  ({
    CREDIT: 'green',
    DEBIT: 'red',
  })[type] || 'blue';

export const getAmountStyle = (amount) => ({
  color: amount >= 0 ? 'green' : 'red',
});
