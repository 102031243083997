import React, { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { notification } from 'antd';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('site') || '');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  const loginAction = async (data) => {
    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const res = await response.json();
      if (response.ok) {
        setUser(res.username);
        setToken(res.access_token);
        const decodedToken = jwtDecode(res.access_token);
        localStorage.setItem('site', res.access_token);
        localStorage.setItem('username', res.username);
        localStorage.setItem('roles', res.roles);
        localStorage.setItem('id', decodedToken.id);

        notification.success({
          description: 'Olá, Login efetuado com sucesso!',
        });

        if (res.roles.includes('ADMIN')) {
          navigate('/dashboard');
        } else {
          navigate(`users/${decodedToken.id}`);
        }
      } else {
        notification.error({
          description: res?.message || 'Erro inesperado. Consultar Suporte.',
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const logOut = () => {
    setUser(null);
    setToken('');
    localStorage.removeItem('site');
    localStorage.removeItem('roles');
    localStorage.removeItem('username');
    localStorage.removeItem('id');
    navigate('/login');
  };

  return <AuthContext.Provider value={useMemo(() => ({ token, user, loginAction, logOut }), [token, user, loginAction, logOut])}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
