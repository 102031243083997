import React, { useEffect, useState } from 'react';
import { Avatar, Card } from 'antd';
import { useAuth } from '../../hooks/AuthProvider';
import { ChangePasswordModal } from './ChangePasswordModal';
import { authAxios } from '../../services/auth-axios';

export function UserInfo({ userId }) {
  const auth = useAuth();
  const [userData, setUserData] = useState(null);
  const isUser = localStorage.getItem('id') === userId;

  const fetchData = async () => {
    try {
      const response = await authAxios().get(`/users/${userId}`);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        auth.logOut();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [auth, userId]);

  const buildUserInitials = (name) => {
    if (!name) return '?';

    const names = name.split(' ');

    return names.map((n) => n[0]).join('');
  };

  const actions = [isUser && <ChangePasswordModal />];

  return (
    <Card title="Meus Dados" actions={actions}>
      <Card.Meta avatar={<Avatar size={40}>{buildUserInitials(userData?.name)}</Avatar>} title={userData?.name || '[Nome não informado]'} />

      <p>
        <strong>Email:</strong> {userData?.email}{' '}
      </p>
      <p>
        <strong>CPF:</strong> {userData?.cpf}{' '}
      </p>
      <p>
        <strong>Telefone:</strong> {userData?.phone}{' '}
      </p>
      <p>
        <strong>ID:</strong> {userData?.id} | <strong>Tipo:</strong> {userData?.userType}{' '}
      </p>
    </Card>
  );
}
