import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { authAxios } from '../../services/auth-axios';

export function SignupForm() {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function handleRegister(values) {
    try {
      setLoading(true);
      notification.info({ message: 'Criando seu usuário...' });
      await authAxios().post('/users', values);

      notification.success({ message: 'Usuário cadastrado com sucesso!' });
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      const { message } = error.response.data;

      notification.error({ message });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Row justify="center">
      <Col md={16} sm={24}>
        <Card title="Cadastro">
          <Form layout="vertical" onFinish={(values) => handleRegister(values)} form={form} id="registerForm">
            <Form.Item name="name" label="Nome completo" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Digite o nome completo" />
            </Form.Item>

            <Form.Item name="cpf" label="CPF" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Digite o CPF completo" />
            </Form.Item>

            <Form.Item name="phone" label="Telefone" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Digite o seu telefone com DDD" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              required
              hasFeedback
              rules={[
                { type: 'email', message: 'Isso não parece um e-mail válido 🤔' },
                { required: true, message: 'Campo obrigatório!' },
              ]}
            >
              <Input placeholder="Digite seu e-mail" />
            </Form.Item>
            <Form.Item name="password" label="Senha" required hasFeedback>
              <Input.Password placeholder="Digite sua senha" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirmar Senha"
              required
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('As senhas não conferem 🤓☝️'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirme sua senha" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button disabled={loading} form="registerForm" htmlType="submit" block type="primary">
                    Cadastrar
                  </Button>
                </Col>
                <Col span={24}>
                  <Button onClick={() => navigate('/login')} block type="dashed">
                    Voltar ao login
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
