import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { User } from './components/user';
import AuthProvider from './hooks/AuthProvider';
import PrivateRoute from './router/Route';

import { Dashboard } from './components/dashboard';
import { LoginForm, PasswordRecoveryConfirmForm, PasswordRecoveryForm, SignupForm } from './components/login';

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/password-recovery" element={<PasswordRecoveryForm />} />
            <Route path="/password-recovery-confirm" element={<PasswordRecoveryConfirmForm />} />

            <Route element={<PrivateRoute />}>
              <Route path="/users/:userId/*" element={<User />} />
            </Route>

            <Route element={<PrivateRoute />}>
              <Route path="/dashboard/*" element={<Dashboard />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
