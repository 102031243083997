import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import { authAxios } from '../../services/auth-axios';

export function PasswordRecoveryConfirmForm() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const urlData = new URLSearchParams(useLocation().search);
  const token = urlData.get('token');

  const handlePasswordConfirmation = async (values) => {
    const handleError = () => {
      notification.error({
        message: 'Erro ao Trocar senha',
        description: 'Erro ao tentar trocar a senha. Verifique o token e endereço de e-mail e tente novamente.',
        duration: 10,
      });
    };

    try {
      setLoading(true);
      await authAxios().patch('/users/reset-password', values);

      notification.success({
        message: 'Senha alterada com sucesso!',
        description: 'Você será redirecionado para a página de login em 2 segundos.',
        duration: 5,
      });

      setTimeout(() => {
        navigate('/login');
      }, 2000);

      navigate('/login');
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center">
      <Col md={16} sm={24}>
        <Card title="Trocar senha">
          <Card.Meta description="Por favor, preencha os campos abaixo com seu email, a nova senha desejada e o token que você recebeu por email. Essas informações são essenciais para concluir com sucesso a alteração da sua senha." />
          <Form initialValues={{ token }} style={{ marginTop: 16 }} layout="vertical" onFinish={handlePasswordConfirmation} form={form} id="passwordRecoveryConfirmForm">
            <Form.Item
              name="email"
              label="Email"
              required
              rules={[
                { type: 'email', message: 'Isso não parece um e-mail válido 🤔' },
                { required: true, message: 'Campo obrigatório!' },
              ]}
            >
              <Input placeholder="Digite seu e-mail" />
            </Form.Item>
            <Form.Item name="token" label="Token" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Digite o token recebi por e-mail" />
            </Form.Item>

            <Form.Item name="password" label="Senha" required>
              <Input.Password placeholder="Digite sua senha" />
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button disabled={loading} form="passwordRecoveryConfirmForm" htmlType="submit" block type="primary">
                  Confirmar nova senha
                </Button>
              </Col>
              <Col span={24}>
                <Button disabled={loading} onClick={() => navigate('/login')} block type="dashed">
                  Voltar ao login
                </Button>{' '}
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
