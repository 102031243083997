import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Col, Row, Tabs } from 'antd';
import { LogoutButton } from '../shared';
import { Games } from '../games';
import { Formats } from '../formats';
import { UsersTable } from './UsersTable';
import { useRouteName } from '../../router/use-route-name';
import { Prizes } from '../prizes';

export function Dashboard() {
  const navigate = useNavigate();
  const username = localStorage.getItem('username');

  const currentChildPath = useRouteName('dashboard');

  const tabs = [
    {
      key: 'users',
      label: 'Usuários',
    },
    {
      key: 'games',
      label: 'Jogos',
    },
    {
      key: 'formats',
      label: 'Formato',
    },
    {
      key: 'prizes',
      label: 'Premiação',
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <h1>Bem-vindo, {username}!</h1>
      </Col>

      <Col span={24}>
        <LogoutButton />
      </Col>

      <Col span={24}>
        <Tabs
          onTabClick={(tab) => {
            if (currentChildPath === tab) return;

            navigate(tab);
          }}
          type="card"
          defaultActiveKey="1"
          activeKey={currentChildPath}
          items={tabs}
        />
      </Col>
      <Col span={24}>
        <Routes>
          <Route index element={<Navigate to="users" replace />} />
          <Route
            path="users"
            element={
              <Col span={22}>
                <UsersTable />
              </Col>
            }
          />
          <Route
            path="games"
            element={
              <Col span={22}>
                <Games />
              </Col>
            }
          />
          <Route
            path="formats"
            element={
              <Col span={22}>
                <Formats />
              </Col>
            }
          />
          <Route
            path="prizes"
            element={
              <Col span={22}>
                <Prizes />
              </Col>
            }
          />
        </Routes>
      </Col>
    </Row>
  );
}
