import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Flex, notification, Popconfirm, Row, Spin, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/AuthProvider';
import { buildPagination, DEFAULT_PAGINATION_PARAMS } from '../shared/build-pagination';
import { authAxios } from '../../services/auth-axios';
import { GameModal } from './GameModal';

export function Games() {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({ ...DEFAULT_PAGINATION_PARAMS });
  const roles = localStorage.getItem('roles');
  const isAdmin = roles && roles.includes('ADMIN');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currentGameBeingEdited, setCurrentGameBeingEdited] = useState(false);

  const openAdd = () => setAddModalOpen(true);
  const closeAdd = () => setAddModalOpen(false);
  const closeEdit = () => setCurrentGameBeingEdited(false);

  const fetchGames = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get('/games', {
        params: paginationOptions,
      });
      setGames(response.data);
    } catch (error) {
      console.error('Error fetching games:', error);
      notification.error({ message: 'Erro ao buscar dados de jogos' });
      if (error.response && error.response.status === 401) {
        auth.logOut();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await authAxios().delete(`/games/${id}`);
      await fetchGames();
      notification.success({ message: 'Jogo deleteado com sucesso!' });
    } catch (error) {
      console.error('Error fetching games:', error);
      notification.error({ message: 'Erro ao deletar jogo' });
      if (error.response && error.response.status === 401) {
        auth.logOut();
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePaginate = (pageNumber, size) =>
    setPaginationOptions({
      ...paginationOptions,
      size,
      page: pageNumber - 1,
    });

  useEffect(() => {
    fetchGames();
  }, [auth, paginationOptions]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: 'Nome curto',
      dataIndex: 'shortName',
      key: 'shorName',
      width: 220,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      width: 220,
      render: (_, game) => (
        <Flex gap="small" wrap="wrap">
          <Popconfirm title="Deletar jogo" description="Tem certeza que deseja deletar esse jogo?" onConfirm={() => handleDelete(game.id)} okText="Sim, tenho certeza" cancelText="Cancelar">
            <Button type="primary" danger icon={<DeleteOutlined />} size="large" />
          </Popconfirm>

          <GameModal game={game} onGameSave={fetchGames} isOpen={game.id === currentGameBeingEdited} onFinish={closeEdit} operation="edit">
            <Button type="primary" onClick={() => setCurrentGameBeingEdited(game.id)} icon={<EditOutlined />} size="large" />
          </GameModal>
        </Flex>
      ),
    },
  ];

  if (loading) return <Spin size="large" />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {isAdmin && (
            <Col xs={24} md={8}>
              <Card title="Ações" size="small">
                <GameModal onGameSave={fetchGames} isOpen={addModalOpen} onFinish={closeAdd} operation="create">
                  <Button type="primary" onClick={openAdd}>
                    Novo Jogo
                  </Button>
                </GameModal>
              </Card>
            </Col>
          )}
        </Row>
      </Col>

      <Col span={24}>
        <Table rowKey={(record) => record.id} columns={columns} dataSource={games.content} pagination={buildPagination(games, handlePaginate)} />
      </Col>
    </Row>
  );
}
