import { Button, Card, Col, Form, Input, Row, Space, Table, Tooltip } from 'antd';
import { CloseCircleOutlined, CodepenCircleFilled, EditOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AddUserModal } from './AddUserModal';
import { buildPagination, DEFAULT_PAGINATION_PARAMS } from '../shared/build-pagination';
import { authAxios } from '../../services/auth-axios';
import { EditUserModal } from './EditUserModal';

export function UsersTable() {
  const [userData, setUserData] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({ ...DEFAULT_PAGINATION_PARAMS, size: 50 });

  const [loading, setLoading] = useState(false);
  const [searchName, setSearchName] = useState();
  const [form] = Form.useForm();

  const roles = localStorage.getItem('roles');
  const token = localStorage.getItem('site');

  const isAdmin = roles && roles.includes('ADMIN');

  const fetchUsers = async (params) => {
    try {
      setLoading(true);
      const response = await authAxios().get('/users', {
        params,
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setLoading(false);
    }
  };

  const params = { ...paginationOptions, name: searchName };

  useEffect(() => {
    fetchUsers(params);
  }, [isAdmin, token, paginationOptions]);

  const columns = [
    { key: 'id', title: 'ID', dataIndex: 'id' },
    { key: 'name', title: 'Nome', dataIndex: 'name' },
    { key: 'phone', title: 'Telefone', dataIndex: 'phone' },
    { key: 'cpf', title: 'CPF', dataIndex: 'cpf' },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: 'actions',
      title: 'Ações ',
      render: (_, record) => (
        <Space>
          <Tooltip title="Editar os dados do usuário">
            <EditUserModal user={record} onUserEdit={() => fetchUsers(params)} />
          </Tooltip>

          <Link to={`/users/${record.id}/info`}>
            <Tooltip title="Acessar este usuário">
              <Button type="primary" icon={<UserOutlined />} size="large" />
            </Tooltip>
          </Link>
        </Space>
      ),
    },
  ];

  const handlePaginate = (pageNumber, size) =>
    setPaginationOptions({
      ...paginationOptions,
      size,
      page: pageNumber - 1,
    });

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={8}>
        <Card title="Ações" size="small">
          <AddUserModal onUserAdded={() => fetchUsers(params)} />
        </Card>
      </Col>
      <Col xs={24} md={14}>
        <Card title="Pesquisa" size="small" style={{ maxHeight: '95px' }}>
          <Row gutter={[16, 16]}>
            <Col>
              <Space>
                <Button form="userTableForm" type="primary" htmlType="submit" icon={<SearchOutlined />} />
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    if (!form.getFieldValue('name')) return;

                    form.resetFields(['name']);
                    fetchUsers(paginationOptions);
                  }}
                  icon={<CloseCircleOutlined />}
                />
              </Space>
            </Col>
            <Col flex="auto">
              <Form
                onFinish={() => {
                  if (!form.getFieldValue('name')) return;

                  fetchUsers(params);
                }}
                form={form}
                id="userTableForm"
              >
                <Form.Item name="name">
                  <Input placeholder="Digite o nome para pesquisar" onChange={(e) => setSearchName(e.target.value)} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>{!loading && <Table size="small" dataSource={userData.content} columns={columns} pagination={buildPagination(userData, handlePaginate)} />}</Col>
    </Row>
  );
}
