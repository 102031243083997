export const DEFAULT_PAGINATION_PARAMS = {
  page: 0,
  size: 10,
};
export const buildPagination = (data, handlePaginate) =>
  data?.content?.length
    ? {
        total: data.totalSize,
        pageSize: data.pageable.size,
        current: data.pageable.number + 1,
        onChange: handlePaginate,
        onShowSizeChange: handlePaginate,
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
      }
    : false;
