import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, notification, Row } from 'antd';
import { authAxios } from '../../services/auth-axios';

export function ChangePasswordModal() {
  const [form] = Form.useForm();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const open = () => setModalOpen(true);

  const close = () => setModalOpen(false);

  const handleChangePassword = async ({ password }) => {
    setLoading(true);

    try {
      await authAxios().patch('/users/password', { password });

      notification.success({ message: 'Senha alterada com sucesso!' });

      setTimeout(() => {
        close();
      }, 500);
    } catch (error) {
      notification.error({ message: 'Erro ao alterar senha!' });
      console.error('Error changing password:', error);
      close();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button type="dashed" danger onClick={open}>
        Trocar de senha
      </Button>
      <Modal open={modalOpen} onCancel={close} footer={null} afterClose={form.resetFields}>
        <Card title="Alterar Senha">
          <Form layout="vertical" onFinish={handleChangePassword} form={form} id="changePasswordForm">
            <Form.Item name="password" label="Senha" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input.Password placeholder="Digite sua nova senha" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button disabled={loading} form="changePasswordForm" htmlType="submit" block type="primary">
                    Alterar senha
                  </Button>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={close}>
                    Fechar
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
}
