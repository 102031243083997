export const getStatusLabel = (status) =>
  ({
    DONE: 'Concluída',
    CANCELED: 'Cancelada',
  })[status] || 'Desconhecida';

export const getStatusColor = (status) =>
  ({
    DONE: 'green',
    CANCELED: 'red',
  })[status] || 'blue';
