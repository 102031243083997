import { authAxios } from '../../services/auth-axios';

export const fetchMovements = async ({ userId, paginationOptions, onFinish, setLoading, auth }) => {
  try {
    setLoading(true);

    const response = await authAxios().get(`/movements/user/${userId}`, {
      params: paginationOptions,
    });

    onFinish(response.data);
  } catch (error) {
    console.error('Error fetching user movements:', error);

    if (error.response && error.response.status === 401) {
      auth.logOut();
    }
  } finally {
    setLoading(false);
  }
};

export const fetchBalance = async ({ userId, onFinish, auth }) => {
  try {
    const response = await authAxios().get(`/users/${userId}`);
    onFinish(response.data.balance);
  } catch (error) {
    console.error('Error fetching user data:', error);
    if (error.response && error.response.status === 401) {
      auth.logOut();
    }
  }
};
