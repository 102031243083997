import { Button } from 'antd';
import React from 'react';

export function Balance({ balance }) {
  return (
    <Button type="text" disabled style={{ color: balance >= 0 ? 'green' : 'red' }}>
      R${balance}
    </Button>
  );
}
