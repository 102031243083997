import React, { useEffect, useState } from 'react';
import { Button, Card, Col, notification, Row, Spin, Table, Tag, Tooltip } from 'antd';
import { FrownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/AuthProvider';
import { TransferModal } from './TransferModal';
import { getStatusColor, getStatusLabel } from './helpers';
import { buildPagination, DEFAULT_PAGINATION_PARAMS } from '../shared/build-pagination';
import { formatDateWithHours } from '../shared/helpers';
import { authAxios } from '../../services/auth-axios';

export function UserTransfers({ userId }) {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({ ...DEFAULT_PAGINATION_PARAMS });
  const username = localStorage.getItem('username');
  const isUser = localStorage.getItem('id') === userId;

  const fetchTransfers = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get(`/transfers/user/${userId}`, {
        params: paginationOptions,
      });
      setTransfers(response.data);
    } catch (error) {
      console.error('Error fetching user transfers:', error);
      notification.error({ message: 'Erro ao buscar dados de transferências' });
      if (error.response && error.response.status === 401) {
        auth.logOut();
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePaginate = (pageNumber, size) =>
    setPaginationOptions({
      ...paginationOptions,
      size,
      page: pageNumber - 1,
    });

  useEffect(() => {
    fetchTransfers();
  }, [auth, userId, paginationOptions]);

  function ValueTableTitle() {
    const explanation = (
      <>
        <p>O valor mostrado abaixo se dá pela seguinte fórmula: </p>
        <p>
          <span style={{ color: 'lightblue' }}>Valor transferido</span>
          <span style={{ color: 'red' }}> - 5% </span>
          <span style={{ color: 'green' }}> = Valor recebido</span>
        </p>

        <p>
          Exemplo: Em uma <span style={{ color: 'lightblue' }}>transferência de 10 reais do Huguinho</span> para o Zezinho, o <span style={{ color: 'lightblue' }}> Huguinho enviará 10 reais </span> e
          o<span style={{ color: 'green' }}> Zezinho receberá 9,50 reais </span>. A diferença entre os valores de <span style={{ color: 'red' }}> 0,50 reais é a taxa de transferência. </span>
        </p>
      </>
    );

    return (
      <Tooltip title={explanation}>
        <span>
          Valor <QuestionCircleOutlined />
        </span>
      </Tooltip>
    );
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Situação',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (status) => (
        <Tag color={getStatusColor(status)} key={status}>
          {getStatusLabel(status)}
        </Tag>
      ),
    },
    {
      title: 'Quem enviou',
      dataIndex: 'sourceUser',
      key: 'sourceUser',
      width: 140,
      render: (sourceUser) => <span>{sourceUser.username}</span>,
    },
    {
      title: 'Quem recebeu',
      dataIndex: 'destinationUser',
      key: 'destinationUser',
      width: 140,
      render: (destinationUser) => <span>{destinationUser.username}</span>,
    },
    {
      title: 'Mensagem',
      dataIndex: 'message',
      key: 'message',
      ellipsis: true,
    },
    {
      key: 'value',
      title: <ValueTableTitle />,
      width: 220,
      render: (transfer) => (
        <>
          <span style={{ color: 'blue' }}>R$ {transfer.originalValue}</span>
          <span style={{ color: 'red' }}> - 5%</span>
          <span style={{ color: 'green' }}> = R${transfer.finalValue}</span>
        </>
      ),
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      width: 220,
      render: (date) => formatDateWithHours(date),
    },
  ];

  if (isUser) {
    const column = {
      key: 'returnTransfer',
      title: 'Retornar transferência',
      ellipsis: true,
      render: (transfer) => {
        const isDone = transfer.status === 'DONE';
        const isNotItself = transfer.sourceUser.username !== username;
        const underTwoDays = new Date(transfer.date) > new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);

        const canCancel = isDone && isNotItself && underTwoDays;

        if (canCancel) {
          return (
            // eslint-disable-next-line no-use-before-define
            <Button onClick={() => returnTransfer(transfer.id)} type="primary">
              Retornar transferência
            </Button>
          );
        }
        const message = (
          <>
            <p>Para retornar uma transferência, ela deve:</p>
            <ul>
              <li>Ter sido concluída {isDone ? '✅' : '❌'}</li>
              <li>Não ter sido enviada por você: {isNotItself ? '✅' : '❌'}</li>
              <li>Não ter sido enviada há mais de 2 dias {underTwoDays ? '✅' : '❌'}</li>
            </ul>
          </>
        );
        return (
          <Tooltip title={message}>
            <Button type="dashed" disabled>
              <FrownOutlined />
            </Button>
          </Tooltip>
        );
      },
    };

    columns.push(column);
  }

  const returnTransfer = async (transferId) => {
    try {
      await authAxios().post('/transfers/return', { transferId });
      notification.success({ message: 'Transferência retornada com sucesso' });
      await fetchTransfers();
    } catch (error) {
      console.error('Error returning transfer:', error);
      notification.error({ message: 'Erro ao retornar transferência' });
    }
  };

  if (loading) return <Spin size="large" />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Card title="Ações" size="small">
              {isUser && <TransferModal userId={userId} onTransferAdded={fetchTransfers} />}
            </Card>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Table columns={columns} dataSource={transfers.content} pagination={buildPagination(transfers, handlePaginate)} />
      </Col>
    </Row>
  );
}
