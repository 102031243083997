import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Spin, Tabs } from 'antd';
import { useAuth } from '../../hooks/AuthProvider';
import { LogoutButton } from '../shared';
import { authAxios } from '../../services/auth-axios';
import { UserInfo } from './UserInfo';
import { UserMovementsAdmin, UserMovementsRegular } from '../movements';
import { UserTransfers } from '../transfers';
import { Games } from '../games';
import { Formats } from '../formats';
import { useRouteName } from '../../router/use-route-name';

export function User() {
  const auth = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const userIdFromStorage = localStorage.getItem('id');
  const roles = localStorage.getItem('roles');
  const isAdmin = roles && roles.includes('ADMIN');

  const currentChildPath = useRouteName('users/:userId');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await authAxios().get(`/users/${userId}`);
        setUser(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          auth.logOut();
        } else {
          navigate(`/users/${userIdFromStorage}`);
        }
      }
    };

    fetchUser();
  }, [auth, userId, navigate, userIdFromStorage]);

  const tabs = [
    {
      key: 'info',
      label: 'Minhas informações',
    },
    {
      key: 'movements',
      label: isAdmin ? 'Extrato - Visão usuário' : 'Ver meu Extrato',
    },
    isAdmin && {
      key: 'admin-movements',
      label: 'Extrato - Visão admin',
    },
    {
      key: 'transfers',
      label: 'Transferências',
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <h1>Usuário: {user.name || user.email}</h1>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          {isAdmin && (
            <Col>
              <Button type="primary" onClick={() => navigate('/dashboard')}>
                Dashboard
              </Button>
            </Col>
          )}
          <Col>
            <LogoutButton />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Tabs
          onTabClick={(tab) => {
            if (currentChildPath === tab) return;

            navigate(tab);
          }}
          type="card"
          activeKey={currentChildPath}
          items={tabs}
        />
      </Col>
      <Col span={24}>
        <Routes>
          <Route index element={<Navigate to="info" replace />} />
          <Route
            path="/info"
            element={
              <Col xs={24} md={8}>
                <UserInfo userId={userId} />
              </Col>
            }
          />
          <Route
            path="movements"
            element={
              <Col span={24}>
                <UserMovementsRegular userId={userId} />
              </Col>
            }
          />
          <Route
            path="admin-movements"
            element={
              <Col span={22}>
                <UserMovementsAdmin userId={userId} />
              </Col>
            }
          />
          <Route
            path="transfers"
            element={
              <Col span={22}>
                <UserTransfers userId={userId} />
              </Col>
            }
          />
        </Routes>
      </Col>
      <Outlet />
    </Row>
  );
}
