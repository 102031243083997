import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, InputNumber, Modal, notification, Popconfirm, Radio, Row } from 'antd';
import { authAxios } from '../../services/auth-axios';

function AddMovementModal({ userId, onMovementAdded }) {
  const [form] = Form.useForm();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const typeOptions = [
    { label: 'Crédito 💰', value: 'CREDIT' },
    { label: 'Débito', value: 'DEBIT' },
  ];

  const open = () => setModalOpen(true);

  const close = () => setModalOpen(false);

  const handleAddMovement = async (values) => {
    setLoading(true);

    try {
      await authAxios().post('/movements', {
        ...values,
        createdBy: userId,
        userId,
      });

      close();
      onMovementAdded?.();
    } catch (error) {
      console.error('Error adding movement:', error);
      notification.error({
        message: 'Erro ao adicionar movimento',
        description: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={open}>
        Novo movimento
      </Button>
      <Modal open={modalOpen} onCancel={close} footer={null} afterClose={form.resetFields}>
        <Card title="Adicionar Movimento">
          <Form layout="vertical" onFinish={handleAddMovement} form={form} id="addMovementForm">
            <Form.Item label="Tipo" name="type" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Radio.Group options={typeOptions} optionType="button" buttonStyle="solid" />
            </Form.Item>

            <Form.Item
              label="Valor"
              name="amount"
              required
              rules={[
                { required: true, message: 'Campo obrigatório!' },
                { min: 0, type: 'number', message: 'Valor mínimo é 0!' },
              ]}
            >
              <InputNumber defaultValue={0} />
            </Form.Item>

            <Form.Item name="observation" label="Observação" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Adicione uma explicação para a movimentação" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Popconfirm title="Adicionar movimento" description="TEM CERTEZA que deseja adicionar esse movimento?" onConfirm={form.submit} okText="Sim" cancelText="Não">
                    <Button disabled={loading} block type="primary">
                      Adicionar Movimento
                    </Button>
                  </Popconfirm>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={close}>
                    Fechar
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
}

export default AddMovementModal;
