import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Flex, notification, Popconfirm, Row, Spin, Table, Tag, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/AuthProvider';
import { buildPagination, DEFAULT_PAGINATION_PARAMS } from '../shared/build-pagination';
import { authAxios } from '../../services/auth-axios';
import { PrizeModal } from './PrizeModal';
import { canDelete, canEdit, getStatusColor, getStatusLabel } from './helpers';

export function Prizes() {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [prizes, setPrizes] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({ ...DEFAULT_PAGINATION_PARAMS });
  const roles = localStorage.getItem('roles');
  const isAdmin = roles && roles.includes('ADMIN');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currentPrizeBeingEdited, setCurrentPrizeBeingEdited] = useState(false);

  const fetchPrizes = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get('/prizestructures', {
        params: paginationOptions,
      });
      setPrizes(response.data);
    } catch (error) {
      console.error('Error fetching prizes:', error);
      notification.error({ message: 'Erro ao buscar premiações' });
      if (error.response && error.response.status === 401) {
        auth.logOut();
      }
    } finally {
      setLoading(false);
    }
  };

  const openAdd = () => setAddModalOpen(true);
  const closeAdd = async () => {
    await fetchPrizes();
    setAddModalOpen(false);
  };
  const closeEdit = async () => {
    await fetchPrizes();
    setCurrentPrizeBeingEdited(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await authAxios().delete(`/prizestructures/${id}`);
      await fetchPrizes();
      notification.success({ message: 'Premiação deleteado com sucesso!' });
    } catch (error) {
      console.error('Error fetching games:', error);
      notification.error({ message: 'Erro ao deletar premiação' });
      if (error.response && error.response.status === 401) {
        auth.logOut();
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePaginate = (pageNumber, size) =>
    setPaginationOptions({
      ...paginationOptions,
      size,
      page: pageNumber - 1,
    });

  useEffect(() => {
    fetchPrizes();
  }, [auth, paginationOptions]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Situação',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (status) => (
        <Tag color={getStatusColor(status)} key={status}>
          {getStatusLabel(status)}
        </Tag>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      width: 220,
      render: (_, prize) => {
        const editButtonDisabled = !canEdit(prize);
        const deleteButtonDisabled = !canDelete(prize);
        const editTooltipMessage = editButtonDisabled ? 'Não é possível editar premiações já ativas' : '';
        const deleteTooltipMessage = deleteButtonDisabled ? 'Não é possível deletar premiações já deletadas' : '';

        return (
          <Flex gap="small" wrap="wrap">
            <Popconfirm
              title="Deletar premiação"
              description="Tem certeza que deseja deletar essa premiação?"
              onConfirm={() => handleDelete(prize.id)}
              okText="Sim, tenho certeza"
              cancelText="Cancelar"
            >
              <Tooltip title={deleteTooltipMessage}>
                <Button disabled={deleteButtonDisabled} type="primary" danger icon={<DeleteOutlined />} size="large" />
              </Tooltip>
            </Popconfirm>

            <PrizeModal prizeId={prize.id} onPrizeSave={fetchPrizes} isOpen={prize.id === currentPrizeBeingEdited} onFinish={closeEdit} operation="edit">
              <Tooltip title={editTooltipMessage}>
                <Button disabled={editButtonDisabled} type="primary" onClick={() => setCurrentPrizeBeingEdited(prize.id)} icon={<EditOutlined />} size="large" />
              </Tooltip>
            </PrizeModal>
          </Flex>
        );
      },
    },
  ];

  if (loading) return <Spin size="large" />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {isAdmin && (
            <Col xs={24} md={8}>
              <Card title="Ações" size="small">
                <PrizeModal onPrizeSave={fetchPrizes} isOpen={addModalOpen} onFinish={closeAdd} operation="create">
                  <Button type="primary" onClick={openAdd}>
                    Nova Premiação
                  </Button>
                </PrizeModal>
              </Card>
            </Col>
          )}
        </Row>
      </Col>

      <Col span={24}>
        <Table columns={columns} dataSource={prizes.content} pagination={buildPagination(prizes, handlePaginate)} />
      </Col>
    </Row>
  );
}
