import React from 'react';
import { Button } from 'antd';
import { useAuth } from '../../hooks/AuthProvider';

export function LogoutButton() {
  const auth = useAuth();

  return (
    <Button onClick={auth.logOut} type="primary" danger>
      Logout
    </Button>
  );
}
