import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Select } from 'antd';
import { authAxios } from '../../services/auth-axios';
import { DebounceSelect } from '../shared';

export function TransferModal({ userId, onTransferAdded }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const ownId = Number(localStorage.getItem('id'));
  const [form] = Form.useForm();

  const open = () => setModalOpen(true);

  const close = () => setModalOpen(false);

  const fetchUsers = async (name) => {
    setLoading(true);
    try {
      const response = await authAxios().get('/users', { params: { size: 10, name } });
      return response.data.content
        .filter(({ id }) => id !== ownId)
        .map((user) => ({
          label: user.name,
          value: user.id,
        }));
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      setLoading(true);
      fetchUsers(userId)
        .then((usersResponse) => setUsers(usersResponse))
        .catch((error) => console.error('Error fetching users:', error))
        .finally(() => setLoading(false));
    }
  }, [modalOpen, userId]);

  const buildUserOptions = () =>
    users.map((user) => ({
      value: user.id,
      label: user.name || user.email,
    }));

  const handleTransfer = async (values) => {
    setLoading(true);

    try {
      await authAxios().post('/transfers', { ...values, userId });
      notification.success({
        message: 'Transferência realizada com sucesso!',
      });
    } catch (error) {
      console.error('Error adding transfer:', error);
      notification.error({ message: 'Erro ao realizar transferência!', description: error.response.data.message });
    } finally {
      setLoading(false);
      setModalOpen(false);
      onTransferAdded();
    }
  };

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Button type="primary" onClick={open}>
        Novo transferência
      </Button>
      <Modal open={modalOpen} onCancel={close} footer={null} afterClose={form.resetFields}>
        <Card title="Nova Transferência">
          <Form layout="vertical" onFinish={handleTransfer} form={form} id="addTransferForm">
            <Form.Item label="Destinatário" name="destinationUserId" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <DebounceSelect placeholder="Comece a digitar o nome..." fetchOptions={fetchUsers} />
            </Form.Item>

            <Form.Item
              label="Valor debitado de mim"
              name="originalValue"
              onChange={(e) => {
                const value = (e.target.value * 0.95).toFixed(2);
                form.setFieldValue('valueAfterFee', value);
              }}
              required
            >
              <InputNumber style={{ width: '100%' }} defaultValue={0} />
            </Form.Item>

            <Form.Item
              label="Valor creditado ao destinatário"
              name="valueAfterFee"
              required
              onChange={(e) => {
                const value = (e.target.value / 0.95).toFixed(2);
                form.setFieldValue('originalValue', value);
              }}
            >
              <InputNumber style={{ width: '100%' }} defaultValue={0} />
            </Form.Item>

            <Form.Item name="message" label="Mensagem" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input.TextArea rows={3} placeholder="Adicione uma mensagem explicando a transferência" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Popconfirm title="Efetuar Transferêcia" description="TEM CERTEZA que deseja efetuar essa transferêcia?" onConfirm={form.submit} okText="Sim" cancelText="Não">
                    <Button disabled={loading} type="primary" block>
                      Efetuar Transferêcia
                    </Button>
                  </Popconfirm>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={close}>
                    Fechar
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
}
