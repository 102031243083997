import axios from 'axios';

export const authAxios = () => {
  const baseURL = process.env.REACT_APP_BACKEND_URL;
  const storageToken = localStorage.getItem('site');
  const token = storageToken ? `Bearer ${storageToken}` : 'invalid-token-will-always-fail';

  if (token === 'invalid-token-will-always-fail') {
    console.error('Invalid Token. Please login again');
  }

  const defaultOptions = {
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  const instance = axios.create(defaultOptions);

  return {
    get: (url, options = {}) => instance.get(url, { ...options }),
    post: (url, data, options = {}) => instance.post(url, data, { ...options }),
    put: (url, data, options = {}) => instance.put(url, data, { ...options }),
    patch: (url, data, options = {}) => instance.patch(url, data, { ...options }),
    delete: (url, options = {}) => instance.delete(url, { ...options }),
  };
};
