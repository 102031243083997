import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, notification, Row } from 'antd';
import { authAxios } from '../../services/auth-axios';

export function GameModal({ game, onGameSave, operation, isOpen, onFinish, children }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const settingsByOperation = {
    edit: {
      operation: authAxios().put,
      url: `/games/${game?.id}`,
    },
    create: {
      operation: authAxios().post,
      url: '/games',
    },
  };
  const setting = settingsByOperation[operation];

  const handleFinish = async (values) => {
    setLoading(true);

    try {
      await setting.operation(setting.url, { ...values });
      notification.success({ message: 'Jogo salvo com sucesso!' });
    } catch (error) {
      console.error('Error adding game:', error);
      notification.error({ message: 'Erro ao salvar jogo!', description: error.response.data.message });
    } finally {
      setLoading(false);
      onFinish();
      onGameSave();
    }
  };

  return (
    <>
      {children}
      <Modal open={isOpen} onCancel={onFinish} footer={null} afterClose={form.resetFields}>
        <Card title="Salvar Jogo">
          <Form initialValues={game} layout="vertical" onFinish={handleFinish} form={form} id="addGameForm">
            <Form.Item name="name" label="Nome" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Adicione o nome do jogo" />
            </Form.Item>

            <Form.Item name="shortName" label="Nome Curto" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Adicione um nome curto do jogo (do dia-a-dia)" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button disabled={loading} form="addGameForm" type="primary" htmlType="submit" block>
                    Salvar Jogo
                  </Button>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={onFinish}>
                    Fechar
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
}
