import { matchRoutes, useLocation } from 'react-router-dom';

export const useRouteName = (parentPath) => {
  const location = useLocation();
  const results = matchRoutes([{ path: `/${parentPath}/:childPath` }], location);

  if (!results?.length) return undefined;

  return results[0]?.params?.childPath;
};
