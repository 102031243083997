export const getStatusLabel = (status) =>
  ({
    ACTIVE: 'Ativa',
    INCOMPLETE: 'Incompleta',
    INACTIVE: 'Desativada',
  })[status] || 'Desconhecida';

export const getStatusColor = (status) =>
  ({
    ACTIVE: 'green',
    INCOMPLETE: 'orange',
    INACTIVE: 'grey',
  })[status] || 'blue';

export const statusOptions = [
  { label: 'Ativa ✅', value: 'ACTIVE' },
  { label: 'Incompleta 🚧', value: 'INCOMPLETE' },
  { label: 'Desativada 💀', value: 'INACTIVE' },
];

export const canEdit = ({ status }) => status === 'INCOMPLETE';

export const canDelete = ({ status }) => status !== 'INACTIVE';

export const generatePositionsArray = (length) =>
  Array.from({ length }, (_, i) => ({
    position: i + 1,
    percentage: 0,
  }));
