import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, notification, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { authAxios } from '../../services/auth-axios';

export function EditUserModal({ user, onUserEdit }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const open = () => setModalOpen(true);

  const close = () => setModalOpen(false);

  const handleEditUser = async (values) => {
    setLoading(true);
    try {
      await authAxios().put(`/users/${user.id}`, { ...user, ...values });
      notification.success({ message: 'Usuário editado com sucesso!' });
    } catch (error) {
      console.error('Error adding user:', error);
      notification.error({ message: 'Erro ao editar usuário', description: error.response.data.message });
    } finally {
      onUserEdit();
      setLoading(false);
      close();
    }
  };

  return (
    <>
      <Button type="primary" onClick={open} size="large" style={{ backgroundColor: 'orange' }} icon={<EditOutlined />} />

      <Modal open={modalOpen} onCancel={close} footer={null} afterClose={form.resetFields}>
        <Card title="Editar Usuário">
          <Form layout="vertical" onFinish={handleEditUser} form={form} id="editUserForm" initialValues={user}>
            <Form.Item name="name" label="Nome completo">
              <Input placeholder="Digite o nome completo" />
            </Form.Item>

            <Form.Item name="cpf" label="CPF">
              <Input placeholder="Digite o CPF completo" />
            </Form.Item>

            <Form.Item name="phone" label="Telefone">
              <Input placeholder="Digite o seu telefone com DDD" />
            </Form.Item>

            <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'Isso não parece um e-mail válido 🤔' }]}>
              <Input placeholder="Digite o e-mail" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button disabled={loading} form="editUserForm" htmlType="submit" type="primary" block>
                    Editar usuário
                  </Button>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={close}>
                    Fechar
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
}
