import React, { useState } from 'react';
import { Card, Col, Form, Modal, notification, Row, Button, Input } from 'antd';
import { authAxios } from '../../services/auth-axios';

export function AddUserModal({ onUserAdded }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const open = () => setModalOpen(true);

  const close = () => setModalOpen(false);

  const handleAddUser = async (values) => {
    setLoading(true);
    try {
      await authAxios().post('/users', values);
      notification.success({ message: 'Usuário adicionado com sucesso!' });
    } catch (error) {
      console.error('Error adding user:', error);
      notification.error({ message: 'Erro ao adicionar usuário', description: error.response.data.message });
    } finally {
      onUserAdded();
      setLoading(false);
      close();
    }
  };

  return (
    <>
      <Button type="primary" onClick={open}>
        Novo usuário
      </Button>
      <Modal open={modalOpen} onCancel={close} footer={null} afterClose={form.resetFields}>
        <Card title="Adicionar Usuário">
          <Form layout="vertical" onFinish={handleAddUser} form={form} id="addUserForm">
            <Form.Item name="name" label="Nome completo" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Digite o nome completo" />
            </Form.Item>

            <Form.Item name="cpf" label="CPF" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Digite o CPF completo" />
            </Form.Item>

            <Form.Item name="phone" label="Telefone" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Digite o seu telefone com DDD" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              required
              rules={[
                { type: 'email', message: 'Isso não parece um e-mail válido 🤔' },
                { required: true, message: 'Campo obrigatório!' },
              ]}
            >
              <Input placeholder="Digite o e-mail" />
            </Form.Item>
            <Form.Item name="password" label="Senha" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input.Password placeholder="Digite a senha" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button disabled={loading} form="addUserForm" htmlType="submit" type="primary" block>
                    Adicionar usuário
                  </Button>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={close}>
                    Fechar
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
}
