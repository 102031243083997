import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, notification, Radio, Row, Spin } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { generatePositionsArray, statusOptions } from './helpers';
import { block, required } from '../shared/default-props';
import { createPrizeStructureItem, createPrizeStructureItemValue, deletePrizeItem, deletePrizeItemValue, getPrizeStructure, upsertPrizeStructure } from './api';

export function PrizeModal({ prizeId, operation, isOpen, onFinish, children }) {
  const [loading, setLoading] = useState(false);
  const [prize, setPrize] = useState();
  const [form] = Form.useForm();

  const fetchPrizeStructure = async (id) => {
    setLoading(true);
    const prizeResponse = await getPrizeStructure({ prizeId: id });
    setPrize(prizeResponse);
    form.setFieldsValue(prizeResponse);
    setLoading(false);
  };

  const onFormFinish = async (prizeValues) => {
    const { name, description, status } = prizeValues;
    const prizePersisted = await upsertPrizeStructure({
      prizeId: prize?.id,
      payload: { name, description, status },
    });

    const itemsPromises = prizeValues.items?.map(async (prizeItem) => {
      const prizeItemPersisted = prizeItem?.id
        ? prizeItem
        : await createPrizeStructureItem({
            prizeId: prizePersisted.id,
            payload: {
              minimumPlayers: prizeItem.minimumPlayers,
              maximumPlayers: prizeItem.maximumPlayers,
              topCut: prizeItem.topCut,
            },
          });

      const valuesPromise = prizeItem.values?.map(async (prizeValue) => {
        if (!prizeValue?.id) {
          await createPrizeStructureItemValue({
            prizeId: prizePersisted.id,
            itemId: prizeItemPersisted.id,
            payload: {
              position: prizeValue.position,
              percentage: prizeValue.percentage,
            },
          });
        }
      });
      await Promise.all(valuesPromise || []);
    });
    await Promise.all(itemsPromises || []);

    await fetchPrizeStructure(prizePersisted.id);
  };

  useEffect(() => {
    if (isOpen && prizeId) {
      fetchPrizeStructure(prizeId);
    }
  }, [isOpen]);

  return (
    <>
      {children}

      <Modal open={isOpen} onCancel={onFinish} footer={null} afterClose={form.resetFields}>
        <Card title="Salvar Premiação">
          {loading && <Spin size="large" />}
          {!loading && (
            <Form layout="vertical" form={form} onFinish={onFormFinish} id="addPrizeForm">
              <Form.Item name="name" label="Nome" {...required}>
                <Input placeholder="Adicione o nome da premiação" />
              </Form.Item>

              <Form.Item name="description" label="Descrição" {...required}>
                <Input placeholder="Adicione a descrição da premiação" />
              </Form.Item>

              {operation !== 'create' && (
                <Form.Item name="status" label="Status" {...required}>
                  <Radio.Group options={statusOptions} optionType="button" buttonStyle="solid" />
                </Form.Item>
              )}

              <Form.List name="items">
                {(itemFields, { add, remove }) => (
                  <Row gutter={[16, 16]}>
                    {itemFields.map((itemField, itemIndex) => {
                      const itemPath = ['items', itemIndex];
                      const currentItem = form.getFieldValue(itemPath);
                      const itemPersisted = !!currentItem?.id;

                      return (
                        <Col span={24}>
                          <Card key={itemField.key}>
                            <Divider>Item {itemIndex + 1}</Divider>

                            <Row gutter={[16, 16]}>
                              <Col span={7}>
                                <Form.Item name={[itemIndex, 'topCut']} label="Corte" {...required}>
                                  <InputNumber
                                    disabled={itemPersisted}
                                    onBlur={(v) => {
                                      const valuesPath = ['items', itemIndex, 'values'];
                                      const currentItemValues = form.getFieldValue(valuesPath);
                                      if (!currentItemValues?.length) {
                                        const topCut = v.target.value;
                                        const defaultValues = generatePositionsArray(topCut);
                                        form.setFieldValue(valuesPath, defaultValues);
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={7}>
                                <Form.Item name={[itemIndex, 'minimumPlayers']} label="Mínimo" {...required}>
                                  <InputNumber disabled={itemPersisted} />
                                </Form.Item>
                              </Col>

                              <Col span={7}>
                                <Form.Item name={[itemIndex, 'maximumPlayers']} label="Máximo" {...required}>
                                  <InputNumber disabled={itemPersisted} />
                                </Form.Item>
                              </Col>

                              <Col span={3} style={{ alignSelf: 'center' }}>
                                <Button
                                  type="primary"
                                  danger
                                  icon={<DeleteOutlined />}
                                  onClick={async () => {
                                    const item = form.getFieldValue(['items', itemIndex]);
                                    if (item?.id) {
                                      await deletePrizeItem({ prizeId: prize.id, itemId: item.id });
                                    }
                                    remove(itemField.name);
                                  }}
                                />
                              </Col>

                              <Col span={24}>
                                <Form.List name={[itemIndex, 'values']}>
                                  {(valueFields, { add: addValue, remove: removeValue }) => (
                                    <Row gutter={[16, 16]}>
                                      {valueFields.map((valueField, valueIndex) => {
                                        const valuePath = ['items', itemIndex, 'values', valueIndex];
                                        const currentValue = form.getFieldValue(valuePath);
                                        const valuePersisted = !!currentValue?.id;

                                        return (
                                          <>
                                            <Col span={7} offset={7}>
                                              <Form.Item name={[valueIndex, 'position']} label="Posição" {...required}>
                                                <InputNumber disabled={valuePersisted} {...block} />
                                              </Form.Item>
                                            </Col>
                                            <Col span={7}>
                                              <Form.Item name={[valueIndex, 'percentage']} label="Percentual" {...required}>
                                                <InputNumber disabled={valuePersisted} {...block} />
                                              </Form.Item>
                                            </Col>
                                            <Col span={3} style={{ alignSelf: 'center' }}>
                                              <Button
                                                type="primary"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={async () => {
                                                  const value = form.getFieldValue(valuePath);
                                                  if (value?.id) {
                                                    await deletePrizeItemValue({
                                                      prizeId: prize.id,
                                                      itemId: value.prizeStructureItem.id,
                                                      valueId: value.id,
                                                    });
                                                  }
                                                  removeValue(valueField.name);
                                                }}
                                              />
                                            </Col>
                                          </>
                                        );
                                      })}
                                      <Col span={24}>
                                        <Form.Item>
                                          <Button type="primary" onClick={() => addValue()} block style={{ backgroundColor: 'green' }}>
                                            <PlusOutlined /> Adicionar valor
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  )}
                                </Form.List>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })}
                    <Col span={24}>
                      <Form.Item>
                        <Button type="primary" onClick={() => add()} block style={{ backgroundColor: 'green' }}>
                          <PlusOutlined /> Adicionar item
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.List>

              <Divider />

              <Form.Item>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Button disabled={loading} form="addPrizeForm" type="primary" htmlType="submit" block>
                      Salvar Premiação
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Button disabled={loading} block type="dashed" onClick={onFinish}>
                      Fechar
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Modal>
    </>
  );
}
