import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import { useAuth } from '../../hooks/AuthProvider';

export function LoginForm() {
  const navigate = useNavigate();
  const roles = localStorage.getItem('roles');
  const id = localStorage.getItem('id');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('site');
    if (isLoggedIn) {
      if (roles && roles.includes('ADMIN')) {
        navigate('/dashboard');
      } else {
        navigate(`/users/${id}`);
      }
    }
  }, [auth, id, navigate, roles]);

  const handleSubmitEvent = async (values) => {
    setLoading(true);
    notification.info({
      description: 'Aguarde um momento, estamos autenticando suas credenciais.',
    });
    await auth.loginAction(values);
    setLoading(false);
  };

  function CardCover() {
    return (
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <img src="https://arsieimghost.s3.sa-east-1.amazonaws.com/beelzebufo.png" alt="Logo" style={{ maxWidth: '200px' }} />
        </Col>
      </Row>
    );
  }

  return (
    <Row justify="center">
      <Col md={16} sm={24}>
        <Card title="Entrar" cover={<CardCover />}>
          <Form layout="vertical" onFinish={handleSubmitEvent} form={form} id="loginForm">
            <Form.Item name="username" label="Email" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input placeholder="Digite seu email" />
            </Form.Item>

            <Form.Item name="password" label="Senha" required rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <Input.Password placeholder="Digite sua senha" />
            </Form.Item>

            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button disabled={loading} form="loginForm" htmlType="submit" block type="primary">
                    Entrar
                  </Button>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={() => navigate('/signup')}>
                    Cadastrar-se
                  </Button>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={() => navigate('/password-recovery')}>
                    Esqueci minha senha
                  </Button>
                </Col>
                <Col span={24}>
                  <Button disabled={loading} block type="dashed" onClick={() => navigate('/password-recovery-confirm')}>
                    Já possuo um token para recuperar senha
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
