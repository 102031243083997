import { notification } from 'antd';
import { authAxios } from '../../services/auth-axios';

async function updatePrizeStructure({ prizeId, payload }) {
  try {
    const response = await authAxios().put(`/prizestructures/${prizeId}`, payload);
    notification.success({ message: `Premiação [${response.data.name}] salva!` });
    return response.data;
  } catch (error) {
    console.log(error);
    notification.error({ message: 'Erro ao salvar premiação!', description: error.response.data.message });
  }
}

async function createPrizeStructure({ payload }) {
  try {
    const response = await authAxios().post('/prizestructures', payload);
    notification.success({ message: `Premiação ${response.data.name} criada!` });
    return response.data;
  } catch (error) {
    console.log(error);
    notification.error({ message: 'Erro ao criar premiação!', description: error.response.data.message });
  }
}

export async function upsertPrizeStructure({ prizeId, payload }) {
  return prizeId ? updatePrizeStructure({ prizeId, payload }) : createPrizeStructure({ payload });
}

export async function createPrizeStructureItem({ prizeId, payload }) {
  try {
    const response = await authAxios().post(`prizestructures/${prizeId}/items`, payload);
    notification.success({ message: `Item [${response.data.id}] salvo!` });
    return response.data;
  } catch (error) {
    console.log(error);
    notification.error({ message: 'Erro ao criar item!', description: error.response.data.message });
  }
}

export async function createPrizeStructureItemValue({ prizeId, itemId, payload }) {
  try {
    const response = await authAxios().post(`prizestructures/${prizeId}/items/${itemId}/values`, payload);
    notification.success({ message: `Valor [${response.data.id}] salvo!` });
    return response.data;
  } catch (error) {
    console.log(error);
    notification.error({ message: 'Erro ao criar valor!', description: error.response.data.message });
  }
}

export async function getPrizeStructure({ prizeId }) {
  try {
    const response = await authAxios().get(`/prizestructures/${prizeId}`, { params: { children: true } });
    return response.data;
  } catch (error) {
    console.log(error);
    notification.error({ message: 'Erro ao buscar dados de premiação!', description: error.response.data.message });
  }
}

export async function deletePrizeItem({ prizeId, itemId }) {
  try {
    await authAxios().delete(`/prizestructures/${prizeId}/items/${itemId}`);
    notification.success({ message: `Item [${itemId}] deletado!` });
  } catch (error) {
    console.log(error);
    notification.error({ message: 'Erro ao deletar item!', description: error.response.data.message });
  }
}

export async function deletePrizeItemValue({ prizeId, itemId, valueId }) {
  try {
    await authAxios().delete(`/prizestructures/${prizeId}/items/${itemId}/values/${valueId}`);
    notification.success({ message: `Valor [${valueId}] deletado!` });
  } catch (error) {
    console.log(error);
    notification.error({ message: 'Erro ao deletar valor!', description: error.response.data.message });
  }
}
